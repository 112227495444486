import { render, staticRenderFns } from "./platforms.template.pug?vue&type=template&id=58856404&lang=pug&external"
import script from "./platforms.script.js?vue&type=script&lang=js&external"
export * from "./platforms.script.js?vue&type=script&lang=js&external"
import style0 from "./platforms.style.css?vue&type=style&index=0&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports