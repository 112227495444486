import PProviderService from '@/services/api/Provider/PProvider'
import GGoogleService from '@/services/api/General/GGoogle'
import GZoomService from '@/services/api/General/GZoom'
import GUserAccountService from '@/services/api/General/GUserAccount'

export default {
  name: 'v-view-account',
  computed: {

    /**
     * 
     */
    authToken() {
      return this.$store.getters['AuthModule/token'].split('Bearer ').join('')
    },

    /**
     * 
     */
    googleLink() {
      return `${ GGoogleService.getLink() }&state=${ this.authToken }`
      // return GGoogleService.getLink()
    },

    /**
     * 
     */
    zoomLink() {
      return `${ GZoomService.getLink() }&state=${ this.authToken }`
    },
  },
  data() {
    return {
      busy: {
        representative: false,
      },
      gUserAccount: {},
      pProvider: {},
    }
  },
  methods: {

    /**
     * 
     */
    async getUserAccountByToken() {
      this.busy.representative = true
      this.gUserAccount = await GUserAccountService.getByToken(this.$route.query.state)

      // set auth data
      await this.$store.dispatch('AuthModule/setData', { 
        data: {
          accessToken: this.$route.query.state,
          tokenType: 'Bearer',
          ...this.gUserAccount.data,
          userAccount: undefined,
        } 
      })

      // set user data
      await this.$store.dispatch('UserModule/setData', { 
        data: this.gUserAccount.data
      })

      // get provider
      if (this.gUserAccount.data.profile.code === 'provider') {
        await PProviderService.getById(this.gUserAccount.data.profile.relatedId).then(pProvider => {
          return this.$store.dispatch('ProviderModule/setData', {
            data: pProvider,
          })
        })
      }

      this.$toast.success('Conectado exitosamente.')
      this.busy.representative = false
    },
  },
  mounted() {
    if (this.$route.query.state) {
      this.getUserAccountByToken()
    }
  },
}